<script lang="ts">
  import { onMount } from "svelte";
  import { switchLanguage } from "../scripts/language";
  import {
    ArrowRight,
    ChevronRight,
    Icon,
    XCircle,
  } from "svelte-hero-icons";
  import type { Locales } from "../i18n";

  interface AvailableLanguageTranslation {
    locale: string;
    label: string;
    callToAction: string;
  }

  type Hidden = { visible: false };
  type Visible = {
    visible: true;
    preferredLanguage: string;
    translation: AvailableLanguageTranslation | undefined;
  };

  const HIDE_COOKIE_KEY = "hide-preferred-lang";

  export let translations: AvailableLanguageTranslation[];
  export let currentLocale: Locales;

  let status: Hidden | Visible = { visible: false };

  onMount(() => {
    const preferred =
      window.navigator.language ?? window.navigator.languages[0] ?? "en";
    const current = currentLocale;

    const p = new Intl.Locale(preferred);
    const c = new Intl.Locale(current);

    status = (() => {
      if (
        document.cookie
          .split("; ")
          .find((row) => row.startsWith(`${HIDE_COOKIE_KEY}=`))
          ?.split("=")[1] === "1"
      ) {
        return { visible: false };
      }

      if (p.language === c.language) {
        return { visible: false };
      }

      return {
        visible: true,
        preferredLanguage: p.language,
        translation: translations.find(({ locale }) => locale === p.language),
      };
    })();
  });

  function hide() {
    status = { visible: false };
    let now = new Date();
    let expires = new Date(now.setDate(now.getDate() + 7));
    document.cookie = `${HIDE_COOKIE_KEY}=1; expires=${expires.toUTCString()}; max-age=${60 * 60 * 24 * 7}; path=/;`;
  }

  function hideAndSwitch() {
    const language = status.visible ? status.preferredLanguage : undefined;
    hide();
    if (language) {
      switchLanguage(language);
    }
  }
</script>

{#if status.visible && status.translation}
  <div class="relative">
    <div
      class="fixed z-20 bg-white ring-1 ring-black ring-opacity-5 bottom-8 right-14 md:right-8 rounded-md p-4 shadow-lg"
    >
      <div class="flex flex-row gap-x-6">
        <div class="flex flex-col gap-y-2">
          <p class="text-gray-500 text-sm">{status.translation.label}</p>
          <button
            class="group flex flex-row items-center gap-x-1 font-semibold text-sm hover:text-gray-600"
            on:click|preventDefault={hideAndSwitch}
          >
            <div>{status.translation.callToAction}</div>
            <div class="w-3 h-3 mt-0.5">
              <Icon src={ChevronRight} solid class="block group-hover:hidden" />
              <Icon src={ArrowRight} solid class="hidden group-hover:block" />
            </div>
          </button>
        </div>

        <button
          class="self-start text-gray-400 hover:text-gray-600"
          on:click|preventDefault={hide}
        >
          <Icon src={XCircle} solid class="w-6 h-6" />
        </button>
      </div>
    </div>
  </div>
{/if}
